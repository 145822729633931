import React, { Component } from "react";
import { connect } from "react-redux";

import {
  resetList,
  getAssignedAlbumsById,
} from "../../redux/actions/users/get-data-site";
import {
  searchAlbum,
} from "../../redux/actions/users/management";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";
import { decodeHtml } from "../../utils";
import viewIcon from "../../assets/images/view.svg"
const config = require("../../config/config");

class UserReports extends Component {
  constructor(props) {
    super(props);

    this.tmr = null;
    this.state = {
      album_list: "",
      currency: "",
      total_count: 0,

      current_page: 1,
      page_neighbours: 2,
      pagination: 10,
      page_num: "",
    };
  }
  componentDidMount() {
    if (!localStorage.id) {
      window.location.href = "/login";
    }
    this.onPageClick(1);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * Getting album list
     */
    if (
      this.props.get_registered_albums &&
      this.props.get_registered_albums !== prevProps.get_registered_albums
    ) {
      this.setState({
        album_list: this.props.get_registered_albums.list,
        currency: this.props.get_registered_albums.currency,
        page_num: this.props.get_registered_albums.page_num,
        total_count: this.props.get_registered_albums.total_count,
      });
    }
    if (
      this.props.msg_registered_albums &&
      this.props.msg_registered_albums !== prevProps.msg_registered_albums
    ) {
      toast(this.props.msg_registered_albums);
      const { resetList } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        resetList();
        this.tmr = null;
      }, 3000);
    }
    if (
      this.props.get_search_albums &&
      this.props.get_search_albums !== prevProps.get_search_albums
    ) {
      this.setState({
        album_list: this.props.get_search_albums.list,
      });
    }
    if (
      this.props.msg_search_albums &&
      this.props.msg_search_albums !== prevProps.msg_search_albums
    ) {
      toast(this.props.msg_search_albums);
      const { reset } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        reset();
        this.tmr = null;
      }, 3000);
    }
  }

  onSearchKeyUp = (code) => {
    if (code === 13) {
      this.onSearchAlbum();
    }
  };
  onSearchAlbum = () => {
    if (this.state.album_name === "") {
      this.onPageClick(this.state.current_page);
    } else {
      const data = {
        role_id: localStorage.getItem("id"),
        user_id: this.props.match.params.id || this.props?.match?.params?.id,
        album_name: this.state.album_name,
      };
      const { searchAlbum } = this.props;
      if (searchAlbum) {
        searchAlbum(data);
      }
      this.setState({
        album_list: "",
        paid_history: "",
        allChecked: false,
        allSelected: false,
        new_checked: [],
        current_page: 1,
        page_num: "",
        show_flag: false,
        registered_date: "",
        flag_edit: "",

        showPayment: false,
      });
    }
  };

  onPageClick = (item) => {
    this.setState({
      current_page: item,
    });
    const { getAssignedAlbumsById } = this.props;

    const data = {
      id: localStorage.id,
      current_page: item,
      page_neighbours: this.state.page_neighbours,
      pagination: this.state.pagination,
    };
    if (getAssignedAlbumsById) {
      getAssignedAlbumsById(data);
    }
    this.setState({
      allChecked: false,
      new_checked: [],
    });
  };
  onView = (id) => {
    this.props.history.push("/user-track-list/" + id);
  };
  exportTableToCSV = (filename) => {
    let csv = [];
    let rows = document.querySelectorAll("table tr");
    const imageColumnIndex = 4; // The index of the image column
    const viewColumnIndex = 8; // The index of the view column
    for (let i = 0; i < rows.length; i++) {
      let row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (let j = 0; j < cols.length; j++) {
        if (j === imageColumnIndex || j === viewColumnIndex) continue; // Skip the image column
        row.push(decodeHtml(cols[j].innerText));
      }
      csv.push(row.join(","));
    }
    this.downloadCSV(csv.join("\n"), filename);
  };
  downloadCSV = (csv, filename) => {
    let csvFile;
    let downloadLink;
    csvFile = new Blob(["\ufeff"+csv], {type: "text/csv;charset=charset=utf-8"});
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  render() {
    const { t } = this.props;
    const pageArray = [];
    if (this.state.page_num) {
      for (
        let k = this.state.page_num.start_page;
        k <= this.state.page_num.end_page;
        k++
      ) {
        pageArray.push(k);
      }
    }
    return (
      <>
        <div
          className="spinning-curtain"
          style={{ display: this.props.spinning ? "flex" : "none" }}
        >
          <div className="lds-dual-ring" />
        </div>
        <ToastContainer />
        <div className="col-darkBlue txt-30 txt-bold pb-20 pl-40">
          {t("assigned_albums")}:{" "}
          {this.state.total_count && this.state.total_count}
        </div>
        <div className="form-bg">
          <div className="flex-space assign">
          <div>
              <div className="justify-left user-search">
                <div className="mr-id-10">
                  <input
                    id="album_id"
                    className="count mouse-cursor"
                    placeholder={t("input_album_name")}
                    value={this.state.album_name}
                    onChange={(e) =>
                      this.setState({ album_name: e.target.value })
                    }
                    onKeyUp={(e) => this.onSearchKeyUp(e.keyCode)}
                  />
                </div>
                <div
                  className="btn-common user-search mouse-cursor col-white"
                  onClick={this.onSearchAlbum}
                >
                  {t("search")}
                </div>
              </div>
            </div>
            <div
              className="btn-common action assign mouse-cursor col-white"
              onClick={() => this.props.history.goBack()}
            >
              {t("back")}
            </div>
          </div>
          <div className="table-p">
            <table className="tList">
              <thead>
                <tr className="table-list">
                  <th>{t("no")}</th>
                  <th>{t("name")}</th>
                  <th>{t("tracks")}</th>
                  <th>{t("assigned_date")}</th>
                  <th>{t("image")}</th>
                  <th>{t("total")}</th>
                  <th>{t("paid")}</th>
                  <th>{t("owed")}</th>
                  <th>{t("view")}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.album_list &&
                  this.state.album_list.map((item, key) => {
                    return (
                      <tr key={key} className="table-list">
                        <td>{key + 1}</td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.album_list[key].name,
                            }}
                          />
                        </td>
                        <td>{this.state.album_list[key].tracks.length}</td>
                        <td>
                          {new Date(
                            this.state.album_list[key].updated_date
                          ).toLocaleDateString()}
                        </td>
                        <td
                          className="mouse-cursor"
                          onClick={() =>
                            (window.location = this.state.album_list[key].path)
                          }
                        >
                          {this.state.album_list[key].thumbnail && (
                            <img
                              className="thumbnail-size"
                              src={this.state.album_list[key].thumbnail}
                              alt=""
                            />
                          )}
                        </td>
                        <td className="col-heavyDark txt-bold">
                        {(this.state.album_list[key].total_amount
                            ? this.state.album_list[key].total_amount.toFixed(2)
                            : 0) +
                            (this.state.currency
                              ? this.state.currency
                              : config.SYMBOL[0])}
                        </td>
                        <td>
                          <div>
                          {(this.state.album_list[key].paid_amount
                            ? this.state.album_list[key].paid_amount.toFixed(2)
                            : 0) +
                            (this.state.currency
                              ? this.state.currency
                              : config.SYMBOL[0])}
                          </div>
                        </td>
                        <td className="col-paragraphBg txt-bold">
                          {(this.state.album_list[key].owed_amount
                            ? this.state.album_list[key].owed_amount.toFixed(2)
                            : 0) +
                            (this.state.currency
                              ? this.state.currency
                              : config.SYMBOL[0])}
                        </td>
                        <td>
                          <div
                            className="mr-10 mouse-cursor icon-flex tooltip"
                            onClick={(e) =>
                              this.onView(
                                this.state.album_list[key].album_id.toString()
                              )
                            }
                          >
                            <img
                              className="icon-size"
                              src={viewIcon}
                              alt=""
                            />
                            <span className="tooltiptext">
                              {t("view_track_list")}
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div>
            <div className="flex-space mt-30">
              <div
                className="btn-common action assign mouse-cursor col-white"
                onClick={() => window.print("")}
              >
                {t("print")}
              </div>
              <div className="help-center-align">
                <div
                  className="product-btn justify-center"
                  onClick={() => this.onPageClick(1)}
                >
                  <svg
                    width="11"
                    height="15"
                    viewBox="0 0 11 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.60496 14.6383C9.42024 14.6383 9.23359 14.5779 9.07773 14.457L0.923018 8.02084C0.724826 7.86414 0.609375 7.62814 0.609375 7.37704C0.609375 7.12782 0.724826 6.88993 0.923018 6.73512L9.0431 0.332906C9.40485 0.047818 9.934 0.104458 10.2246 0.459402C10.5151 0.814346 10.4574 1.33355 10.0956 1.61863L2.79141 7.37704L10.1322 13.1713C10.4939 13.4564 10.5517 13.9756 10.2611 14.3305C10.0937 14.5326 9.85126 14.6383 9.60496 14.6383Z"
                      fill="black"
                      fillOpacity="0.65"
                    />
                  </svg>
                </div>
                {this.state.page_num &&
                  pageArray &&
                  pageArray.map((item, key) => {
                    return (
                      <div
                        className={
                          this.state.current_page &&
                          this.state.current_page === item
                            ? "product-btn justify-center btn-search"
                            : "product-btn justify-center col-darkBlue"
                        }
                        key={key}
                        onClick={() => this.onPageClick(item)}
                      >
                        {item}
                      </div>
                    );
                  })}
                <div
                  className="product-btn justify-center"
                  onClick={() =>
                    this.onPageClick(this.state.page_num.total_page)
                  }
                >
                  <svg
                    width="11"
                    height="15"
                    viewBox="0 0 11 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.39506 14.6383C1.57978 14.6383 1.76643 14.5779 1.92229 14.457L10.077 8.02084C10.2752 7.86414 10.3906 7.62814 10.3906 7.37704C10.3906 7.12782 10.2752 6.88993 10.077 6.73512L1.95692 0.332906C1.59518 0.047818 1.06603 0.104458 0.775474 0.459402C0.484922 0.814346 0.542647 1.33355 0.904394 1.61863L8.2086 7.37704L0.867834 13.1713C0.506087 13.4564 0.448362 13.9756 0.738914 14.3305C0.906319 14.5326 1.14877 14.6383 1.39506 14.6383Z"
                      fill="black"
                      fillOpacity="0.65"
                    />
                  </svg>
                </div>
              </div>
              <div
                className="btn-common action assign mouse-cursor col-white"
                onClick={() => this.exportTableToCSV("members.csv")}
              >
                {t("csv")}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinning: state.users.spinning,
    get_registered_albums: state.users.get_registered_albums,
    msg_registered_albums: state.users.msg_registered_albums,
    msg_assign_albums: state.users.msg_assign_albums,
    msg_error_assign_albums: state.users.msg_error_assign_albums,
    get_search_albums: state.users.get_search_albums,
    msg_search_albums: state.users.msg_search_albums,
  };
};
export default connect(mapStateToProps, {
  resetList,
  getAssignedAlbumsById,
  searchAlbum,
})(withTranslation("translations")(UserReports));
