import React, { Component } from "react";
import { connect } from "react-redux";
import {
  resetList,
  getAssignedAlbumsById,
  getAllAssignedAlbumsById,
  unassignAlbumsToUser,
  
} from "../redux/actions/users/get-data-site";
import {
  reset,
  updateAlbumPrice,
  searchAlbum,
  updatePublisherPrice
} from "../redux/actions/users/management";
import PaymentUpdate from "./payment-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";
import Prompter from "./Prompter";
import { decodeHtml } from "../utils";
import viewIcon from "../assets/images/view.svg";
import editIcon from "../assets/images/edit.svg";
import updateIcon from "../assets/images/update.svg";
import { all } from "axios";


const config = require("../config/config");

class PublisherAlbums extends Component {
  constructor(props) {
    super(props);
    this.tmr = null;
    this.state = {
      album_list: "",
      all_albums_list: [],
      publisher_name: "",
      currency: "",
      paid_history: "",
      allChecked: false,
      allSelected: false,
      new_checked: [],
      current_page: 1,
      page_neighbours: 2,
      pagination: 10,
      page_num: "",
      show_flag: false,
      registered_date: "",
      flag_edit: "",
      total_count: 0,
      editAll: false,
      showPayment: false,
      album_name: "",
    };
  }
  componentDidMount() {
    this.onPageClick(1);
  }
  componentWillUnmount() {
    this.setState({
      all_albums_list: [],
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * Getting album list
     */
    if (
      this.props.get_registered_albums &&
      this.props.get_registered_albums !== prevProps.get_registered_albums
    ) {
      this.setState({
        album_list: this.props.get_registered_albums.list,
        page_num: this.props.get_registered_albums.page_num,
        publisher_name: this.props.get_registered_albums.publisher_name,
        paid_history: this.props.get_registered_albums.paid_history.reverse(),
        currency: this.props.get_registered_albums.currency,
        total_count: this.props.get_registered_albums.total_count,

        showPayment: false,
        paid_amount: "",
      });
    }
    if (
      this.props.msg_registered_albums &&
      this.props.msg_registered_albums !== prevProps.msg_registered_albums
    ) {
      toast(this.props.msg_registered_albums);
      const { resetList } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        resetList();
        this.tmr = null;
      }, 3000);
    }
    /**
     * Getting all albums
     */
    if (
      this.props.get_all_registered_albums &&
      this.props.get_all_registered_albums !==
        prevProps.get_all_registered_albums
    ) {
      if (this.props.get_all_registered_albums[this.props.match.params.id]) {
        this.setState({
          all_albums_list:
            this.props.get_all_registered_albums[this.props.match.params.id]
              .list,
        });
      }
    }
    if (
      this.props.msg_all_registered_albums &&
      this.props.msg_all_registered_albums !==
        prevProps.msg_all_registered_albums &&
      this.props.msg_all_registered_albums[this.props.match.params.id]
    ) {
      toast(this.props.msg_all_registered_albums[this.props.match.params.id]);
    }
    /**
     *  Check all
     */
    if (this.state.allChecked !== prevState.allChecked) {
      const temp = [];
      if (this.state.allChecked === true) {
        let list = this.state.album_list;
        Object.keys(list).map((item, key) => {
          temp.push(list[key]._id);
          return null;
        });
        this.setState({
          new_checked: temp,
        });
      }
    }
    if (this.state.new_checked !== prevState.new_checked) {
      let temp = [];
      let list = this.state.album_list;
      Object.keys(list).map((item, key) => {
        temp.push(list[key]._id);
        return null;
      });
      if (
        this.state.new_checked.length > 0 &&
        this.state.new_checked.length === temp.length
      ) {
        this.setState({
          allChecked: true,
        });
      } else {
        this.setState({
          allChecked: false,
        });
      }
    }
    /**
     * Assigning Albums
     */
    if (
      this.props.msg_assign_albums &&
      this.props.msg_assign_albums !== prevProps.msg_assign_albums
    ) {
      toast(this.props.msg_assign_albums);
      const { resetList } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        resetList();
        this.tmr = null;
      }, 3000);
      this.onPageClick(1);
    }
    if (
      this.props.msg_error_assign_albums &&
      this.props.msg_error_assign_albums !== prevProps.msg_error_assign_albums
    ) {
      toast(this.props.msg_error_assign_albums);
      const { resetList } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        resetList();
        this.tmr = null;
      }, 3000);
    }
    if (
      this.props.msg_track_update &&
      this.props.msg_track_update !== prevProps.msg_track_update
    ) {
      toast(this.props.msg_track_update);
      const { reset } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        reset();
        this.tmr = null;
      }, 3000);
      this.onPageClick(this.state.current_page);
    }
    if (
      this.props.get_search_albums &&
      this.props.get_search_albums !== prevProps.get_search_albums
    ) {
      this.setState({
        album_list: this.props.get_search_albums.list,
      });
    }
    if (
      this.props.msg_search_albums &&
      this.props.msg_search_albums !== prevProps.msg_search_albums
    ) {
      toast(this.props.msg_search_albums);
      const { reset } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        reset();
        this.tmr = null;
      }, 3000);
    }
  }
  onPageClick = (item) => {
    this.setState({
      current_page: item,
    });
    const { getAssignedAlbumsById } = this.props;

    const data = {
      id: this.props.match.params.id,
      current_page: item,
      page_neighbours: this.state.page_neighbours,
      pagination: this.state.pagination,
    };
    
    if (getAssignedAlbumsById) {
      getAssignedAlbumsById(data);
    }
   
    this.setState({
      allChecked: false,
      new_checked: [],
    });
  };
  onCheckBox = (e) => {
    const { new_checked } = this.state;
    const temp = JSON.parse(JSON.stringify(new_checked));
    if (e.target.checked === true) {
      temp.push(e.target.id);
    } else {
      temp.splice(temp.indexOf(e.target.id), 1);
    }

    this.setState({ new_checked: temp, allSelected: false });
    
  };
  onAllCheck = () => {
    
    this.setState({
      allSelected: false,
      allChecked: !this.state.allChecked,
      new_checked: [],
      editAll:false

    });
  };
  onAssign = () => {
    const { unassignAlbumsToUser } = this.props;
    if (unassignAlbumsToUser) {
      const data = {
        role_id: localStorage.id,
        publisher_id: this.state.user_id || this.props?.match?.params?.id,
        new_checked: this.state.new_checked,
        allSelected: this.state.allSelected,
      };
      unassignAlbumsToUser(data);
    }
  };
  showPaymentModal = () => {
    this.setState({
      showPayment: true,
    });
  };
  hidePaymentModal = () => {
    this.setState({
      showPayment: false,
    });
  };
  onView = (id) => {
    if (navigator.userAgent && navigator.userAgent.includes("Firefox"))
      window.location =
        "/publisher/tracks/" + this.props.match.params.id + "-" + id;
    else
      window.open(
        "/publisher/tracks/" + this.props.match.params.id + "-" + id,
        "_blank"
      );
  };
  onShowMore = (e) => {
    if (this.state.registered_date === e.registered_date) {
      this.setState({
        show_flag: !this.state.show_flag,
      });
    } else {
      this.setState({
        show_flag: true,
        registered_date: e.registered_date,
      });
    }
  };

  exportTableToCSV = (filename, selector) => {
    let csv = [];
    let rows = document.querySelectorAll(selector + " tr");
    const imageColumnIndex = 4; // The index of the image column
    const viewColumnIndex = 8; // The index of the view column
    for (let i = 0; i < rows.length; i++) {
      let row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (let j = 0; j < cols.length; j++) {
        if (j === imageColumnIndex || j === viewColumnIndex) continue; // Skip the image column
        row.push(decodeHtml(cols[j].innerText));
      }
      csv.push(row.join(","));
    }
    this.downloadCSV(csv.join("\n"), filename);
  };
  downloadCSV = (csv, filename) => {
    let csvFile;
    let downloadLink;
    csvFile = new Blob(["\ufeff" + csv], {
      type: "text/csv;charset=charset=utf-8",
    });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  /**
   * Price per track
   */
  onEdit = (id, price) => {
    this.setState({
      flag_edit: id,
      price: price,
    });
  };
  onInputAlbumId = (code) => {
    if (code === 13) {
      this.onUpdate();
    }
  };
  onUpdate = (updatePreviousDetails) => {
    const { updateAlbumPrice } = this.props;
    if (updateAlbumPrice) {
      const data = {
        role_id: localStorage.id,
        album_id: this.state.flag_edit,
        price: this.state.price,
        updatePreviousDetails,
      };
      updateAlbumPrice(data);
    }
    this.setState({
      flag_edit: "",
      price: "",
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value || 0,
    });
  };
  onSearchKeyUp = (code) => {
    if (code === 13) {
      this.onSearchAlbum();
    }
  };
  onSearchAlbum = () => {
    if (this.state.album_name === "") {
      this.onPageClick(this.state.current_page);
    } else {
      const data = {
        role_id: localStorage.getItem("id"),
        user_id: this.props.match.params.id || this.props?.match?.params?.id,
        album_name: this.state.album_name,
      };
      const { searchAlbum } = this.props;
      if (searchAlbum) {
        searchAlbum(data);
      }
      this.setState({
        album_list: "",
        paid_history: "",
        allChecked: false,
        allSelected: false,
        new_checked: [],
        current_page: 1,
        page_num: "",
        show_flag: false,
        registered_date: "",
        flag_edit: "",

        showPayment: false,
      });
    }
  };

  getSelectedAlbums() {
    let output = [];
    const { new_checked, album_list, allSelected, all_albums_list } =
      this.state;
    if (allSelected && all_albums_list && all_albums_list.length) {
      output = all_albums_list;
    } else if (new_checked && new_checked.length) {
      new_checked.forEach((checked) => {
        const album = album_list.find((album) => album._id === checked);
        output.push(album);
      });
    }
    return output;
  }

  getTotalOwed() {
    const { new_checked, album_list, allSelected, all_albums_list } =
      this.state;
    let total = 0;

    if (allSelected && all_albums_list && all_albums_list.length) {
      all_albums_list.forEach((album) => {
        const { owed_amount } = album || {};
        if (owed_amount) {
          total += owed_amount;
        }
      });
    } else if (new_checked && new_checked.length) {
      new_checked.forEach((checked) => {
        const album = album_list.find((album) => album._id === checked);
        const { owed_amount } = album || {};
        if (owed_amount) {
          total += owed_amount;
        }
      });
    }

    if (total > 0) {
      return total;
    }
  }

  getAmountOwedComponent() {
    const { t } = this.props;
    const { currency } = this.state;
    let total = this.getTotalOwed();
    if (total > 0) {
      return (
        <div className="flex-space assign">
          <div
            onClick={this.showPaymentModal}
            style={{}}
            className={`btn-common action assign col-white mr-id-10 mouse-cursor`}
          >
            {t("pay_now")}
          </div>
          <div className="assign">
            {t("total_amount") + ": "}
            <span style={{ color: "red" }}>
              {" " +
                total.toFixed(2) +
                (currency ? currency : config.SYMBOL[0])}
            </span>
          </div>
        </div>
      );
    }
  }

  getAllAlbums = async () => {
    if (
      !this.props.get_all_registered_albums ||
      !this.props.get_all_registered_albums[this.props.match.params.id] ||
      !this.state.all_albums_list?.length
    ) {
      const { getAllAssignedAlbumsById } = this.props;

      const data = {
        id: this.props.match.params.id,
      };
      if (getAllAssignedAlbumsById) {
        getAllAssignedAlbumsById(data);
      }
      // this.setState({
      //   all_albums_list: []
      // });
    }

    this.setState({ allSelected: !this.state.allSelected });
  };

  allEdit(){
      this.setState({editAll:true,allPrice:this.state.album_list[0].amount_per_one})
  }
  getEditAll(){
    const { t } = this.props;
    if(this.state.allChecked )
    {
      const trigger=(<div className="mr-10 mouse-cursor icon-flex tooltip">
        <img
          className="icon-size"
          src={updateIcon}
          alt=""
        />
        <span className="tooltiptext">Update the track payment amount</span>
      </div>);
      return (
    <>
    {this.state.editAll? <>
    <Prompter
    trigger={trigger}
    title={t("important") + "!"}
    message={t("how_to_update")}
    buttons={[
      {
        title: t("from_now"),
        onClick: () => this.onUpdateAll(),
        className: "btn-common action assign mouse-cursor col-white"
        // style: { backgroundColor: "red" },
      },
      {
        title: t("since_the_beginning"),
        onClick: () => this.onUpdateAll(true),
        className: "btn-common action assign mouse-cursor col-white"
        // style: { backgroundColor: "blue" },
      },
    ]}
  />
  </>
    :
      <div
          className="mr-10 mouse-cursor icon-flex tooltip"
          onClick={(e) =>
            // this.onEdit(item.album_id, item.amount_per_one)
            this.allEdit()
          }
        >
          <img
            className="icon-size"
            src={editIcon}
            alt=""
          />
          <span className="tooltiptext">
            Edit all tracks
          </span>
        </div>
      }
        </>
        );
      }
  }
  getInputPrice(){
    if(this.state.editAll){
      return (<>
      <input
          id="allPrice"
          type="Number"
          className="price-paid"
          value={this.state.allPrice}
          onChange={(e) => this.onChangeAllPrice(e)}
          onKeyUp={(e) => this.onInputAllAlbum(e.keyCode)}
          style={{ width: 100 }}
        />
      </>)
    }
  }

  onInputAllAlbum = (code) => {
    if (code === 13) {
      this.onUpdateAll();
    }
  };
  onChangeAllPrice = (e) => {
    this.setState({
      [e.target.id]: e.target.value || 0,
    });
  };
  onUpdateAll=(updatePreviousDetails)=>{
    
    const { updatePublisherPrice } = this.props;
    if (updatePublisherPrice) {
      const data = {
        role_id: localStorage.id,
        publisher_id: this.props.match.params.id,
        price: this.state.allPrice,
        updatePreviousDetails,
      };
      updatePublisherPrice(data);
    }
    this.setState({
      allPrice: "",
      editAll:false,
      allChecked:false
    });
  }
  render() {
    const { t } = this.props;
    const pageArray = [];
    if (this.state.page_num) {
      for (
        let k = this.state.page_num.start_page;
        k <= this.state.page_num.end_page;
        k++
      ) {
        pageArray.push(k);
      }
    }
    const trigger = (
      <div className="mr-10 mouse-cursor icon-flex tooltip">
        <img
          className="icon-size"
          src={updateIcon}
          alt=""
        />
        <span className="tooltiptext">Update the track payment amount</span>
      </div>
    );
    return (
      <>
        <div
          className="spinning-curtain"
          style={{ display: this.props.spinning ? "flex" : "none" }}
        >
          <div className="lds-dual-ring" />
        </div>
        <ToastContainer />
        <div className="flex-space assign pb-20">
          <div className="col-darkBlue txt-30 txt-bold pl-40">
            {t("albums_assigned_to") + " "}{" "}
            {this.state.publisher_name && this.state.publisher_name}:{" "}
            {this.state.total_count && this.state.total_count}
          </div>
          
          
          {this.getAmountOwedComponent()}
          
          
          
        </div>
        <div className="form-bg">
          <div className="flex-space assign">
            <div>
              <div className="justify-left user-search">
                <div className="mr-id-10">
                  <input
                    id="album_id"
                    className="count mouse-cursor"
                    placeholder={t("input_album_name")}
                    value={this.state.album_name}
                    onChange={(e) =>
                      this.setState({ album_name: e.target.value })
                    }
                    onKeyUp={(e) => this.onSearchKeyUp(e.keyCode)}
                  />
                </div>
                <div
                  className="btn-common user-search mouse-cursor col-white"
                  onClick={this.onSearchAlbum}
                >
                  {t("search")}
                </div>
              </div>
            </div>
            <div>
              <div className="flex-space justify-right">
                <div className="mr-id-10">
                  {this.getInputPrice()}</div>
                <div className="action assign mouse-cursor col-white mr-id-10">
                  {this.getEditAll()}</div>
                <div
                  style={
                    this.state.allSelected ? { border: "1px solid yellow" } : {}
                  }
                  onClick={this.getAllAlbums}
                  className="btn-common action assign mouse-cursor col-white mr-id-10"
                >
                  {t("select_all")}
                </div>
                <div
                  style={
                    this.state.allSelected || this.state.new_checked?.length
                      ? {}
                      : { opacity: 0.5 }
                  }
                  className={`btn-common action assign col-white${
                    this.state.allSelected || this.state.new_checked?.length
                      ? " mouse-cursor"
                      : ""
                  } mr-id-10`}
                  onClick={() => {
                    if (
                      this.state.allSelected ||
                      this.state.new_checked?.length
                    ) {
                      this.onAssign();
                    } 
                  }}
                >
                  {t("unassign")}
                </div>
                <div
                  className="btn-common action assign mouse-cursor col-white"
                  onClick={() =>
                    this.exportTableToCSV("albums.csv", ".albums-table")
                  }
                >
                  {t("csv")}
                </div>
              </div>
            </div>
          </div>
          <div className="table-p">
            <table className="albums-table">
              <thead>
                <tr className="table-list">
                  <th>{t("no")}</th>
                  <th>{t("album_id")}</th>
                  <th>{t("name")}</th>
                  <th>{t("artists")}</th>
                  <th>{t("tracks")}</th>
                  <th>{t("assigned_date")}</th>
                  <th>{t("image")}</th>
                  <th>{t("price_per_track")}</th>
                  <th>{t("total")}</th>
                  <th>{t("paid")}</th>
                  <th>{t("owed")}</th>
                  <th>{t("action")}</th>
                  <th>
                    <label className="container-event">
                      <input
                        type="checkbox"
                        checked={
                          this.state.allChecked || this.state.allSelected
                        }
                        onChange={this.onAllCheck}
                      />
                      <span className="checkMark" />
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.album_list &&
                  this.state.album_list.length &&
                  this.state.album_list.map((item, key) => {
                    return (
                      <tr key={key} className="table-list">
                        <td>{key + 1}</td>
                        <td>{this.state.album_list[key].album_id}</td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.album_list[key].name,
                            }}
                          />
                        </td>
                        <td>{this.state.album_list[key].artists.length}</td>
                        <td>{this.state.album_list[key].tracks.length}</td>
                        <td>
                          {new Date(
                            this.state.album_list[key].updated_date
                          ).toLocaleString()}
                        </td>
                        <td
                          className="mouse-cursor"
                          onClick={() =>
                            (window.location = this.state.album_list[key].path)
                          }
                        >
                          {this.state.album_list[key].thumbnail && (
                            <img
                              loading="lazy"
                              className="thumbnail-size"
                              src={this.state.album_list[key].thumbnail}
                              alt=""
                            />
                          )}
                        </td>
                        <td>
                          {this.state.flag_edit === item.album_id ? (
                            <input
                              id="price"
                              type="Number"
                              className="price-paid"
                              value={this.state.price}
                              onChange={(e) => this.onChange(e)}
                              onKeyUp={(e) => this.onInputAlbumId(e.keyCode)}
                              style={{ marginTop: 25 }}
                            />
                          ) : (
                            item.amount_per_one +
                            (this.state.currency
                              ? this.state.currency
                              : config.SYMBOL[0])
                          )}
                        </td>
                        <td>
                          {(this.state.album_list[key].total_amount
                            ? this.state.album_list[key].total_amount.toFixed(2)
                            : 0) +
                            (this.state.currency
                              ? this.state.currency
                              : config.SYMBOL[0])}
                        </td>
                        <td>
                          <div>
                            {(this.state.album_list[key].current_paid
                              ? this.state.album_list[key].current_paid.toFixed(
                                  2
                                )
                              : 0) +
                              (this.state.currency
                                ? this.state.currency
                                : config.SYMBOL[0])}
                          </div>
                        </td>
                        <td className="col-paragraphBg txt-bold">
                          {(this.state.album_list[key].owed_amount
                            ? this.state.album_list[key].owed_amount.toFixed(2)
                            : 0) +
                            (this.state.currency
                              ? this.state.currency
                              : config.SYMBOL[0])}
                        </td>
                        <td className="flex-space">
                          {this.state.flag_edit === item.album_id ? (
                            <Prompter
                              trigger={trigger}
                              title={t("important") + "!"}
                              message={t("how_to_update")}
                              buttons={[
                                {
                                  title: t("from_now"),
                                  onClick: () => this.onUpdate(),
                                  className: "btn-common action assign mouse-cursor col-white"
                                  // style: { backgroundColor: "red" },
                                },
                                {
                                  title: t("since_the_beginning"),
                                  onClick: () => this.onUpdate(true),
                                  className: "btn-common action assign mouse-cursor col-white"
                                  // style: { backgroundColor: "blue" },
                                },
                              ]}
                            />
                          ) : (
                            <div
                              className="mr-10 mouse-cursor icon-flex tooltip"
                              onClick={(e) =>
                                this.onEdit(item.album_id, item.amount_per_one)
                              }
                            >
                              <img
                                className="icon-size"
                                src={editIcon}
                                alt=""
                              />
                              <span className="tooltiptext">
                                Edit the track
                              </span>
                            </div>
                          )}

                          <div
                            className="mr-10 mouse-cursor icon-flex tooltip"
                            onClick={(e) =>
                              this.onView(this.state.album_list[key].album_id)
                            }
                          >
                            <img
                              className="icon-size"
                              src={viewIcon}
                              alt=""
                            />
                            <span className="tooltiptext">
                              {t("view_track_list")}
                            </span>
                          </div>
                        </td>
                        <td>
                          <label className="container-event" key={key}>
                            <input
                              id={this.state.album_list[key]._id}
                              type="checkbox"
                              checked={
                                this.state.allSelected ||
                                this.state.new_checked.includes(
                                  this.state.album_list[key]._id
                                )
                              }
                              onChange={(e) => this.onCheckBox(e)}
                            />
                            <span className="checkMark" />
                          </label>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {this.state.page_num && (
            <div className="help-center-align">
              <div
                className="product-btn justify-center"
                onClick={() => this.onPageClick(1)}
              >
                <svg
                  width="11"
                  height="15"
                  viewBox="0 0 11 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.60496 14.6383C9.42024 14.6383 9.23359 14.5779 9.07773 14.457L0.923018 8.02084C0.724826 7.86414 0.609375 7.62814 0.609375 7.37704C0.609375 7.12782 0.724826 6.88993 0.923018 6.73512L9.0431 0.332906C9.40485 0.047818 9.934 0.104458 10.2246 0.459402C10.5151 0.814346 10.4574 1.33355 10.0956 1.61863L2.79141 7.37704L10.1322 13.1713C10.4939 13.4564 10.5517 13.9756 10.2611 14.3305C10.0937 14.5326 9.85126 14.6383 9.60496 14.6383Z"
                    fill="black"
                    fillOpacity="0.65"
                  />
                </svg>
              </div>

              {this.state.page_num &&
                pageArray &&
                pageArray.map((item, key) => {
                  return (
                    <div
                      className={
                        this.state.current_page &&
                        this.state.current_page === item
                          ? "product-btn justify-center btn-search"
                          : "product-btn justify-center col-darkBlue"
                      }
                      key={key}
                      onClick={() => this.onPageClick(item)}
                    >
                      {item}
                    </div>
                  );
                })}
              <div
                className="product-btn justify-center"
                onClick={() => this.onPageClick(this.state.page_num.total_page)}
              >
                <svg
                  width="11"
                  height="15"
                  viewBox="0 0 11 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.39506 14.6383C1.57978 14.6383 1.76643 14.5779 1.92229 14.457L10.077 8.02084C10.2752 7.86414 10.3906 7.62814 10.3906 7.37704C10.3906 7.12782 10.2752 6.88993 10.077 6.73512L1.95692 0.332906C1.59518 0.047818 1.06603 0.104458 0.775474 0.459402C0.484922 0.814346 0.542647 1.33355 0.904394 1.61863L8.2086 7.37704L0.867834 13.1713C0.506087 13.4564 0.448362 13.9756 0.738914 14.3305C0.906319 14.5326 1.14877 14.6383 1.39506 14.6383Z"
                    fill="black"
                    fillOpacity="0.65"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
        {/*  Recent Payment History  */}
        <div style={{ marginTop: 70 }}>
          <div className="pt-20 pb-20 justify-center col-selected-bg txt-20">
            {t("recent_payment_history_to")}{" "}
            {this.state.publisher_name && this.state.publisher_name}
          </div>
        </div>
        <div className="form-bg">
          <div className="table-p">
            <table className="tList paid-table">
              <thead>
                <tr className="table-list">
                  <th>{t("no")}</th>
                  <th>{t("name")}</th>
                  <th>{t("paid_amount")}</th>
                  <th>{t("registered_date")}</th>
                  <th>{t("paid_date")}</th>
                  <th>{t("comment")}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.paid_history &&
                  this.state.paid_history.map((item, key) => {
                    return (
                      <tr key={key} className="table-list">
                        <td>{key + 1}</td>
                        <td>{item.album_name}</td>
                        <td>
                          {parseFloat(item.paid_amount)?.toFixed(2) +
                            (this.state.currency
                              ? this.state.currency
                              : config.SYMBOL[0])}
                        </td>
                        <td>
                          {new Date(item.registered_date).toLocaleString()}
                        </td>
                        <td>{new Date(item.paid_date).toLocaleString()}</td>
                        <td className="txt-word">
                          {item.paid_comment.length <= 40 ? (
                            item.paid_comment
                          ) : (
                            <div>
                              {this.state.show_flag &&
                              this.state.registered_date ===
                                item.registered_date
                                ? item.paid_comment + "   "
                                : item.paid_comment.slice(0, 40) + " ... "}
                              <span
                                className="txt-14 col-heavyDark mouse-cursor under-line"
                                onClick={(e) => this.onShowMore(item)}
                              >
                                {this.state.show_flag &&
                                this.state.registered_date ===
                                  item.registered_date
                                  ? t("less")
                                  : t("show_more")}
                              </span>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div>
            <div className="flex-space justify-right mt-id-10">
              <div
                className="btn-common action assign mouse-cursor col-white"
                onClick={() =>
                  this.exportTableToCSV("payments.csv", ".paid-table")
                }
              >
                {t("csv")}
              </div>
            </div>
          </div>
        </div>
        <PaymentUpdate
          show={this.state.showPayment}
          handleClose={this.hidePaymentModal}
          albums={this.getSelectedAlbums()}
          currency={this.state.currency}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinning: state.users.spinning,
    get_registered_albums: state.users.get_registered_albums,
    msg_registered_albums: state.users.msg_registered_albums,

    get_all_registered_albums: state.users.get_all_registered_albums,
    msg_all_registered_albums: state.users.msg_all_registered_albums,

    msg_assign_albums: state.users.msg_assign_albums,
    msg_error_assign_albums: state.users.msg_error_assign_albums,
    msg_track_update: state.users.msg_track_update,
    get_search_albums: state.users.get_search_albums,
    msg_search_albums: state.users.msg_search_albums,
  };
};
export default connect(mapStateToProps, {
  reset,
  resetList,
  getAssignedAlbumsById,
  getAllAssignedAlbumsById,
  unassignAlbumsToUser,
  updateAlbumPrice,
  searchAlbum,
  updatePublisherPrice
})(withTranslation("translations")(PublisherAlbums));
