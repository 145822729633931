import React, { Component } from "react";
import "../assets/css/dashboard.css";
import { connect } from "react-redux";
import { resetManagement, updatePaid } from "../redux/actions/users/management";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import editIcon from "../assets/images/edit.svg"

const config = require("../config/config");

class PaymentUpdate extends Component {
  constructor(props) {
    super(props);
    this.tmr = null;
    this.state = {
      detail: "",
      paid_date: new Date(),
      albums: props.albums,
      currency: props.currency,
      selectedAlbum: null,
      editAmounts: false,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.albums !== this.props.albums) {
      this.setState({ albums: this.props.albums });
    }

    if (prevProps.currency !== this.props.currency) {
      this.setState({ currency: this.props.currency });
    }

    if (this.props.msg_paid && prevProps.msg_paid !== this.props.msg_paid) {
      toast(this.props.msg_paid);
      const { resetManagement, handleClose } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        resetManagement();
        this.tmr = null;
        handleClose();
        window.location.href = "/user-list";
      }, 2000);
      this.setState({
        detail: "",
        paid_date: new Date(),
        albums: this.props.albums,
        currency: this.props.currency,
        selectedAlbum: null,
        editAmounts: false,
      });
    }
  }
  Cancel = () => {
    const { handleClose } = this.props;
    handleClose();
    this.setState({
      detail: "",
      paid_date: new Date(),
      albums: this.props.albums,
      currency: this.props.currency,
      selectedAlbum: null,
      editAmounts: false,
    });
  };
  onPaymentUpdate = () => {
    // const data = {
    //     role_id: localStorage.id,
    //     publisher_id: this.props.item._id,
    //     comments: this.state.detail,
    //     paid_date: this.state.paid_date,
    // };

    let albums = this.state.albums;
    albums = albums?.map((album) => {
      const { owed_amount } = album;
      return {
        ...album,
        paid_amount: owed_amount,
        comments: this.state.detail,
        paid_date: this.state.paid_date,
      };
    });

    const data = {
      role_id: localStorage.id,
      albums,
      comments: this.state.detail,
      paid_date: this.state.paid_date,
    };

    const { updatePaid } = this.props;
    updatePaid(data);
  };
  onChange = (e) => {
    if (e.target.id === "detail") {
      this.setState({ detail: e.target.value });
    } else {
      let { albums, selectedAlbum } = this.state;
      let index = albums
        .map((album) => album.album_id)
        .indexOf(selectedAlbum.album_id);
      if (index > -1) {
        albums[index].owed_amount = parseFloat(e.target.value) || 0;
      }

      this.setState({
        selectedAlbum: {
          ...selectedAlbum,
          owed_amount: parseFloat(e.target.value) || 0,
        },
        albums,
      });
    }
  };
  handleDate(date) {
    this.setState({
      paid_date: date,
    });
  }
  getTotalOwed() {
    const { albums } = this.state;
    let total = 0;
    if (albums && albums.length) {
      albums.forEach((album) => {
        const { owed_amount } = album || {};
        if (owed_amount) {
          total += owed_amount;
        }
      });
    }

    if (total > 0) {
      return total;
    }
  }
  render() {
    const { t } = this.props;
    const { albums, currency, selectedAlbum, editAmounts } = this.state;
    const showHideClassName = this.props.show
      ? "modal-b display-modal-block"
      : "modal-b display-modal-none";
    const total = this.getTotalOwed();
    return (
      <div className={showHideClassName}>
        <div
          className="spinning-curtain"
          style={{ display: this.props.spinning ? "flex" : "none" }}
        >
          <div className="lds-dual-ring" />
        </div>
        <ToastContainer />
        <section className="modal-article">
          <div className="create-modal-header txt-20 justify-left col-white">
            {t("payment")}
          </div>
          <div className="txt-16" style={{ padding: 30 }}>
            <div className="pb-10">
              {albums?.length + " " + t("albums") + " " + t("selected")}
              {total && (
                <div className="assign">
                  {t("total_amount") + ": "}
                  <span style={{ color: "red" }}>
                    {" " +
                      total.toFixed(2) +
                      (currency ? currency : config.SYMBOL[0])}
                  </span>
                  <div
                    style={{ marginLeft: "10px", display: "inline-flex" }}
                    className="mouse-cursor tooltip"
                    onClick={(e) =>
                      this.setState({ editAmounts: !this.state.editAmounts })
                    }
                  >
                    <img
                      className="icon-size"
                      style={{ width: "15px" }}
                      src={editIcon}
                      alt=""
                    />
                    <span className="tooltiptext">
                      Edit the amounts per album
                    </span>
                  </div>
                </div>
              )}
            </div>
            {editAmounts && albums && albums.length && (
              <div
                style={{
                  marginRight: "10px",
                  marginLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                <div
                  className="flex-common no-scrollbar"
                  style={{
                    overflowX: "scroll",
                    overflowY: "hidden",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    border: "1px solid lightgray",
                    borderRadius: "2px",
                  }}
                >
                  {albums.map((album) => {
                    const { thumbnail, album_id } = album;
                    return (
                      <img
                        key={album_id}
                        id={album_id}
                        onClick={() => this.setState({ selectedAlbum: album })}
                        loading="lazy"
                        className="thumbnail-size"
                        src={thumbnail}
                        alt=""
                        style={
                          selectedAlbum && selectedAlbum.album_id === album_id
                            ? { border: "1px solid blue" }
                            : {}
                        }
                      />
                    );
                  })}
                </div>
                {selectedAlbum && (
                  <div>
                    <div className="pb-10 pt-10" style={{ fontSize: 11 }}>
                      {"Change " + t("payment_amount") + " for "}
                      <span style={{ color: "blue" }}>
                        {selectedAlbum.name}
                      </span>
                    </div>
                    <div>
                      <input
                        type="Number"
                        className="price-paid"
                        value={selectedAlbum.owed_amount}
                        onChange={(e) => this.onChange(e)}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="pt-20 pb-10">{t("paid_date")}</div>
            <div>
              <DatePicker
                id="paid_date"
                type="datetime-local"
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                className="price-paid"
                selected={this.state.paid_date}
                maxDate={new Date().getTime()}
                timeIntervals="20"
                onChange={(e) => this.handleDate(e)}
              />
            </div>
            <div className="pt-20 pb-10">{t("payment_comments")}</div>
            <textarea
              id="detail"
              value={this.state.detail}
              className="payment-detail"
              onChange={(e) => this.onChange(e)}
            />
          </div>
          <div className="flex-grid2 modal-grid2-gaps modal-p">
            <div
              className="btn-common mouse-cursor justify-center col-white"
              onClick={this.Cancel}
            >
              {t("cancel")}
            </div>
            <div
              className="btn-common mouse-cursor justify-center col-white"
              onClick={this.onPaymentUpdate}
            >
              {t("ok")}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinning: state.users.spinning,
    msg_paid: state.users.msg_paid,
  };
};
export default connect(mapStateToProps, {
  resetManagement,
  updatePaid,
})(withTranslation("translations")(PaymentUpdate));
