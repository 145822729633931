import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            translations: {
                usd: 'USD',
                gbp: 'GBP',
                ils: 'ILS',
                eur: 'EUR',
                no: 'No',
                registered_date: 'Registered Date',
                print: 'Print',
                csv: 'CSV',
                back: 'Back',
                initialize: 'Initialize',
                update: 'Update',
                register: 'Register',
                unregister: 'Unregister',
                cancel: 'Cancel',
                delete: 'Delete',
                ok: 'Ok',
                payment_history: 'Payment History',
                owed_amount: 'Owed Amount',
                paid_amount: 'Paid Amount',
                paid_date: 'Paid Date',
                comment: 'Comment',
                less: 'Less',
                show_more: 'Show More',
                assigned_albums: 'Assigned Albums',
                album_id: 'Album ID',
                album_name: "Album Name",
                artists: 'Artists',
                tracks: 'Tracks',
                assigned_date: 'Assigned Date',
                image: 'Image',
                total_amount: 'Total Amount',
                isrc: 'ISRC',
                view: 'View',
                total: "Total Amount",
                view_track_list: 'View the track list',
                user_name: 'User Name',
                phone_number: 'Phone Number',
                email: 'Email',
                password: 'Password',
                confirm_password: 'Confirm Password',
                bank_information: 'Bank Information',
                track_list: 'Track List',
                recent_payment_history_to: "Recent Payment History to ",
                id: 'Id',
                name: 'Name',
                title: 'Title',
                played: 'Played',
                current_price: 'Current Price',
                currency_type: "Currency Type",
                detail_view: 'Detail View',
                view_detailed_info: 'View the detailed info',
                detailed_view_track: 'Detailed view of track',
                track_history: 'Track History',
                country: 'Country',
                platform: 'Platform',
                version: 'Version',
                price_per_played_track: 'Price Per Played Track',
                played_date: 'Played Date',
                album_list: 'Album List',
                counts: 'Counts',
                language: 'Language',
                english: 'English',
                hebrew: 'Hebrew',
                album_id_registration: 'Album Id for registration',
                date: 'Date',
                state: 'State',
                registered_album: 'The registered Albums',
                add_album: 'Add the Album',
                url: 'Url',
                save: 'Save',
                album_list_per_user: 'Album list for assigning to a user',
                publisher: 'Publisher',
                delete_track_history: 'Delete Track History',
                delete_record_modal_content: 'Do you want to delete the selected record really?',
                hi: 'Hi',
                add_user: 'Add User',
                add_artist: 'Add Artist',
                edit_artist: 'Edit Artist',
                user_list: 'User List',
                new_album: 'New Album',
                assign_to_user: 'Assign to User',
                payment: 'Payment',
                report: 'My Reports',
                profile_settings: 'Profile Settings',
                log_out: 'Log Out',
                user: 'User',
                signin: 'Sign In',
                login: 'LOGIN',
                delete_logout_modal_content: 'Do you want to log out really?',
                registration: 'Registration',
                delete_album_modal_content: 'Would you like to unregister these',
                delete_user: 'Delete User',
                delete_user_modal_content: 'Do you want to delete this user really?',
                payment_amount: 'Payment Amount',
                payment_comments: 'Payment Comments',
                albums_assigned_to: 'Albums assigned to',
                input_album_name: 'Please input album name',
                search: 'Search',
                unassign: 'Unassign',
                select_all: 'Select all',
                selected: "Selected",
                pay_now: 'Pay now',
                assign: "Assign",
                assign_all:"Assign All",
                price_per_track: 'Price Per Track',
                action: 'Action',
                update_track_payment: 'Update the track payment amount',
                edit_track: 'Edit the track',
                ip_address: 'Ip Address',
                delete_publisher: 'Delete the publisher',
                input_user_name: 'Please input user name',
                phone: 'phone',
                registered: 'Registered',
                paid: 'Paid',
                owed: 'Owed',
                edit_current_payment: 'Edit the current payment',
                view_album_payment: 'View the album list and payment history',
                edit_publisher_profile: 'Edit the publisher profile',
                albums: "Albums",
                export_detailed_csv: "Export detailed CSV",
                export_simple_csv: "Export simple CSV",
                important: "Important",
                from_now: "From now",
                since_the_beginning: "Since the beginning",
                how_to_update: "How should we update the track price?",
                send_email:"Send Email",
                no_send:"No Send Email",
                home:"Home",

                /**
                 * COUNTRY_LIST
                 */
                afghanistan: 'Afghanistan',
                aland_islands: 'Åland Islands',
                albania: 'Albania',
                algeria: 'Algeria',
                american_samoa: 'American Samoa',
                andorra: 'Andorra',
                angola: 'Angola',
                anguilla: 'Anguilla',
                antarctica: 'Antarctica',
                antigua_and_barbuda: 'Antigua and Barbuda',
                argentina: 'Argentina',
                armenia: 'Armenia',
                aruba: 'Aruba',
                australia: 'Australia',
                austria: 'Austria',
                azerbaijan: 'Azerbaijan',
                bahamas: 'Bahamas',
                bahrain: 'Bahrain',
                bangladesh: 'Bangladesh',
                barbados: 'Barbados',
                belarus: 'Belarus',
                belgium: 'Belgium',
                belize: 'Belize',
                benin: 'Benin',
                bermuda: 'Bermuda',
                bhutan: 'Bhutan',
                bolivia_plurinationa_state_of: 'Bolivia, Plurinational State of',
                bonaire_sint_eustatius_and_saba: 'Bonaire, Sint Eustatius and Saba',
                bosnia_and_herzegovina: 'Bosnia and Herzegovina',
                botswana: 'Botswana',
                bouvet_island: 'Bouvet Island',
                brazil: 'Brazil',
                british_indian_ocean_territory: 'British Indian Ocean Territory',
                brunei_darussalam: 'Brunei Darussalam',
                bulgaria: 'Bulgaria',
                burkina_faso: 'Burkina Faso',
                burundi: 'Burundi',
                cambodia: 'Cambodia',
                cameroon: 'Cameroon',
                canada: 'Canada',
                cape_verde: 'Cape Verde',
                cayman_islands: 'Cayman Islands',
                central_african_republic: 'Central African Republic',
                chad: 'Chad',
                chile: 'Chile',
                china: 'China',
                christmas_island: 'Christmas Island',
                cocos_keeling_islands: 'Cocos (Keeling) Islands',
                colombia: 'Colombia',
                comoros: 'Comoros',
                congo: 'Congo',
                congo_the_democratic_republic_of_the: 'Congo, the Democratic Republic of the',
                cook_islands: 'Cook Islands',
                costa_rica: 'Costa Rica',
                cote_d_Ivoire: "Côte d'Ivoire",
                croatia: 'Croatia',
                cuba: 'Cuba',
                curacao: 'Curaçao',
                cyprus: 'Cyprus',
                czech_republic: 'Czech Republic',
                denmark: 'Denmark',
                djibouti: 'Djibouti',
                dominica: 'Dominica',
                dominican_republic: 'Dominican Republic',
                ecuador: 'Ecuador',
                egypt: 'Egypt',
                el_salvador: 'El Salvador',
                equatorial_guinea: 'Equatorial Guinea',
                eritrea: 'Eritrea',
                estonia: 'Estonia',
                ethiopia: 'Ethiopia',
                falkland_islands_malvinas: 'Falkland Islands (Malvinas)',
                faroe_islands: 'Faroe Islands',
                fiji: 'Fiji',
                finland: 'Finland',
                france: 'France',
                french_guiana: 'French Guiana',
                french_polynesia: 'French Polynesia',
                french_southern_territories: 'French Southern Territories',
                gabon: 'Gabon',
                gambia: 'Gambia',
                georgia: 'Georgia',
                germany: 'Germany',
                ghana: 'Ghana',
                gibraltar: 'Gibraltar',
                greece: 'Greece',
                greenland: 'Greenland',
                grenada: 'Grenada',
                guadeloupe: 'Guadeloupe',
                guam: 'Guam',
                guatemala: 'Guatemala',
                guernsey: 'Guernsey',
                guinea: 'Guinea',
                guinea_bissau: 'Guinea-Bissau',
                guyana: 'Guyana',
                haiti: 'Haiti',
                heard_island_and_mcdonald_islands: 'Heard Island and McDonald Islands',
                holy_see_vatican_city_state: 'Holy See (Vatican City State)',
                honduras: 'Honduras',
                hong_kong: 'Hong Kong',
                hungary: 'Hungary',
                iceland: 'Iceland',
                india: 'India',
                indonesia: 'Indonesia',
                iran_islamic_republic_of: 'Iran, Islamic Republic of',
                iraq: 'Iraq',
                ireland: 'Ireland',
                isle_of_man: 'Isle of Man',
                israel: 'Israel',
                italy: 'Italy',
                jamaica: 'Jamaica',
                japan: 'Japan',
                jersey: 'Jersey',
                jordan: 'Jordan',
                kazakhstan: 'Kazakhstan',
                kenya: 'Kenya',
                kiribati: 'Kiribati',
                korea_democratic_people_republic_of: "Korea, Democratic People's Republic of",
                korea_republic_of: 'Korea, Republic of',
                kuwait: 'Kuwait',
                kyrgyzstan: 'Kyrgyzstan',
                lao_people_democratic_republic: "Lao People's Democratic Republic",
                latvia: 'Latvia',
                lebanon: 'Lebanon',
                lesotho: 'Lesotho',
                liberia: 'Liberia',
                libya: 'Libya',
                liechtenstein: 'Liechtenstein',
                lithuania: 'Lithuania',
                luxembourg: 'Luxembourg',
                macao: 'Macao',
                macedonia_the_former_yugoslav_republic_of: 'Macedonia, the former Yugoslav Republic of',
                madagascar: 'Madagascar',
                malawi: 'Malawi',
                malaysia: 'Malaysia',
                maldives: 'Maldives',
                mali: 'Mali',
                malta: 'Malta',
                marshall_islands: 'Marshall Islands',
                martinique: 'Martinique',
                mauritania: 'Mauritania',
                mauritius: 'Mauritius',
                mayotte: 'Mayotte',
                mexico: 'Mexico',
                micronesia_federated_states_of: 'Micronesia, Federated States of',
                moldova_republic_of: 'Moldova, Republic of',
                monaco: 'Monaco',
                mongolia: 'Mongolia',
                montenegro: 'Montenegro',
                montserrat: 'Montserrat',
                morocco: 'Morocco',
                mozambique: 'Mozambique',
                myanmar: 'Myanmar',
                namibia: 'Namibia',
                nauru: 'Nauru',
                nepal: 'Nepal',
                netherlands: 'Netherlands',
                new_caledonia: 'New Caledonia',
                new_zealand: 'New Zealand',
                nicaragua: 'Nicaragua',
                niger: 'Niger',
                nigeria: 'Nigeria',
                niue: 'Niue',
                norfolk_island: 'Norfolk Island',
                northern_mariana_islands: 'Northern Mariana Islands',
                norway: 'Norway',
                oman: 'Oman',
                pakistan: 'Pakistan',
                palau: 'Palau',
                palestinian_territory_occupied: 'Palestinian Territory, Occupied',
                panama: 'Panama',
                papua_new_guinea: 'Papua New Guinea',
                paraguay: 'Paraguay',
                peru: 'Peru',
                philippines: 'Philippines',
                pitcairn: 'Pitcairn',
                poland: 'Poland',
                portugal: 'Portugal',
                puerto_rico: 'Puerto Rico',
                qatar: 'Qatar',
                reunion: 'Réunion',
                romania: 'Romania',
                russian_federation: 'Russian Federation',
                rwanda: 'Rwanda',
                saint_barthelemy: 'Saint Barthélemy',
                saint_helena_ascension_and_tristan_da_cunha: 'Saint Helena, Ascension and Tristan da Cunha',
                saint_kitts_and_nevis: 'Saint Kitts and Nevis',
                saint_lucia: 'Saint Lucia',
                saint_martin_french_part: 'Saint Martin (French part)',
                saint_pierre_and_miquelon: 'Saint Pierre and Miquelon',
                saint_vincent_and_the_grenadines: 'Saint Vincent and the Grenadines',
                samoa: 'Samoa',
                san_marino: 'San Marino',
                sao_tome_and_principe: 'Sao Tome and Principe',
                saudi_arabia: 'Saudi Arabia',
                senegal: 'Senegal',
                serbia: 'Serbia',
                seychelles: 'Seychelles',
                sierra_leone: 'Sierra Leone',
                singapore: 'Singapore',
                sint_maarten_dutch_part: 'Sint Maarten (Dutch part)',
                slovakia: 'Slovakia',
                slovenia: 'Slovenia',
                solomon_islands: 'Solomon Islands',
                somalia: 'Somalia',
                south_africa: 'South Africa',
                south_georgia_and_the_south_sandwich_islands: 'South Georgia and the South Sandwich Islands',
                south_sudan: 'South Sudan',
                spain: 'Spain',
                sri_lanka: 'Sri Lanka',
                sudan: 'Sudan',
                suriname: 'Suriname',
                svalbard_and_jan_mayen: 'Svalbard and Jan Mayen',
                swaziland: 'Swaziland',
                sweden: 'Sweden',
                switzerland: 'Switzerland',
                syrian_arab_republic: 'Syrian Arab Republic',
                taiwan_province_of_china: 'Taiwan, Province of China',
                tajikistan: 'Tajikistan',
                tanzania_united_republic_of: 'Tanzania, United Republic of',
                thailand: 'Thailand',
                timor_leste: 'Timor-Leste',
                togo: 'Togo',
                tokelau: 'Tokelau',
                tonga: 'Tonga',
                trinidad_and_tobago: 'Trinidad and Tobago',
                tunisia: 'Tunisia',
                turkey: 'Turkey',
                turkmenistan: 'Turkmenistan',
                turks_and_caicos_islands: 'Turks and Caicos Islands',
                tuvalu: 'Tuvalu',
                uganda: 'Uganda',
                ukraine: 'Ukraine',
                united_arab_emirates: 'United Arab Emirates',
                united_kingdom: 'United Kingdom',
                united_states: 'United States',
                united_states_minor_outlying_islands: 'United States Minor Outlying Islands',
                uruguay: 'Uruguay',
                uzbekistan: 'Uzbekistan',
                vanuatu: 'Vanuatu',
                venezuela_bolivarian_republic_of: 'Venezuela, Bolivarian Republic of',
                viet_nam: 'Viet Nam',
                virgin_islands_british: 'Virgin Islands, British',
                virgin_islands_us: 'Virgin Islands, U.S.',
                wallis_and_futuna: 'Wallis and Futuna',
                western_sahara: 'Western Sahara',
                yemen: 'Yemen',
                zambia: 'Zambia',
                zimbabwe: 'Zimbabwe',
                
                dashboard: 'Dashboard',
                plays: 'Plays',
                subscribed: 'Subscribed',
                no_subscribed:'No Subscribed',
                //months list
                January: 'January',
                February: 'February',
                March: 'March',
                April: 'April',
                May:'May',
                June:'June',
                July:'July',
                August:'August',
                September:'September',
                October:'October',
                November: 'November',
                December: 'December',
            }
        },
        he: {
            translations: {
                usd: 'דולר',
                gbp: 'פאונד',
                ils: "ש''ח",
                eur: 'אירו',
                no: 'לא',
                registered_date: 'תאריך הרשמה',
                print: 'הדפס',
                csv: 'קובץ CSV',
                back: 'חזור',
                initialize: 'התחל',
                update: 'עדכן',
                register: 'רשם',
                unregister: 'בטל רישום',
                cancel: 'בטל',
                delete: 'מחק',
                ok: 'אישור',
                payment_history: 'היסטוריית תשלומים',
                owed_amount: 'סכום חייב',
                paid_amount: 'סכום ששולם',
                paid_date: 'תאריך תשלום',
                comment: 'הערה',
                less: 'פחות',
                show_more: 'הצג עוד',
                assigned_albums: 'אלבומים שהוקצו',
                album_id: 'קוד אלבום',
                album_name: "שם אלבום",
                artists: 'אמנים',
                tracks: 'רצועות',
                assigned_date: 'תאריך הקצאה',
                image: 'תמונה',
                total_amount: 'סך הכול',
                isrc: 'ISRC',
                view: 'הצג',
                total: "סך הכל",
                view_track_list: 'הצג את רשימת רצועות',
                user_name: 'שם משתמש',
                phone_number: 'מספר טלפון',
                email: "דוא''ל",
                password: 'סיסמא',
                confirm_password: 'אשר סיסמא',
                bank_information: 'פרטי חשבון בנק',
                track_list: 'רשימת רצועות',
                id: 'קוד',
                name: 'שם',
                title: 'כותרת',
                played: 'ניגן',
                current_price: 'מחיר נוכחי',
                detail_view: 'תצוגת פירוט',
                view_detailed_info: 'צפו במידע המפורט',
                detailed_view_track: 'צפו במידע המפורט',
                track_history: 'היסטורית רצועה',
                country: 'מדינה',
                platform: 'פלטפורמה',
                version: 'גירסה',
                price_per_played_track: 'מחיר לפי רצועה',
                played_date: 'תאריך שהושמע',
                album_list: 'רשימת אלבומים',
                counts: 'ספירות',
                language: 'שפה',
                english: 'אנגלית',
                hebrew: 'עברית',
                album_id_registration: 'קוד אלבום לרישום',
                date: 'תאריך',
                state: 'מדינה',
                registered_album: 'האלבום הרשום',
                add_album: 'הוסף את האלבום',
                url: 'כתובת URL',
                save: 'שמור',
                album_list_per_user: 'רשימת אלבומים להקצאה למשתמש',
                publisher: 'מחבר',
                delete_track_history: 'מחק היסטורית רצועה',
                delete_record_modal_content: 'האם ברצונך למחוק את הרשומה שנבחרה',
                hi: 'היי',
                add_user: 'הוסף משתמש',
                add_artist: 'הוסף אמן',
                edit_artist: 'ערוך אמן',
                user_list: 'רשימת משתמשים',
                new_album: 'אלבום חדש',
                assign_to_user: 'הקצה למשתמש',
                payment: 'תשלום',
                report: 'דוחות שלי',
                profile_settings: 'הגדרות פרופיל',
                log_out: 'התנתק',
                user: 'משתמש',
                signin: 'התחבר',
                login: 'התחבר',
                delete_logout_modal_content: 'האם אתה רוצה להתנתק',
                registration: 'הרשמה',
                delete_album_modal_content: 'האם ברצונך לבטל רישום לאלה',
                delete_user: 'מחק משתמש',
                delete_user_modal_content: 'האם אתה רוצה למחוק את המשתמש הזה?',
                payment_amount: 'סכום לתשלום',
                payment_comments: 'הערות תשלום',
                albums_assigned_to: 'אלבומים הוקצו ל',
                input_album_name: 'הכנס שם אלבום',
                search: 'חיפוש',
                unassign: 'בטל הקצאה',
                select_all: 'בחר הכל',
                selected: "נבחר",
                pay_now: 'שלם עכשיו',
                price_per_track: 'מחיר לרצועה',
                action: 'פעולות',
                update_track_payment: 'עדכן תשלום רצועה',
                edit_track: 'ערוך רצועה',
                ip_address: 'כתובת IP',
                delete_publisher: 'מחק מחבר',
                input_user_name: 'אנא הזן שם משתמש',
                phone: 'טלפון',
                registered: 'רשום',
                paid: 'שולם',
                owed: 'חייב',
                edit_current_payment: 'ערוך את התשלום הנוכחי',
                view_album_payment: 'הצג תשלומי אלבום ',
                edit_publisher_profile: 'ערוך חשבון מחבר',
                assign: "לְהַקְצוֹת",
                assign_all:"לְהַקְצוֹת הכל",
                albums: "אלבומים",
                export_detailed_csv: "הוצא CSV מפורט",
                export_simple_csv: "הוצא CSV פשוט",
                important: "חשוב",
                from_now: "מעכשיו",
                since_the_beginning: "מאז ההתחלה",
                how_to_update: "איך נעדכן את מחיר הרצועה?",
                dashboard: "לוח בקרה",
                plays: 'מספר השמעות',
                subscribed: 'מנוי',
                no_subscribed:'לא מנוי',
                currency_type:'סוג מטבע',
                send_email:'שלח מייל התחברות',
                no_send:"לא לשלוח מייל",
                January:'ינואר',
                February: 'פברואר',
                March: 'מרץ',
                April: 'אפריל',
                May:'מאי',
                June:'יוני',
                July:'יולי',
                August:'אוגוסט',
                September:'ספטמבר',
                October:'אוקטובר',
                November: 'נובמבר',
                December: 'דצמבר',
                home:'בית'
                
            }
        },
    },
    fallbackLng: "en",
    debug: true,
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false, // we use content as keys
    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },
    react: {
        wait: true
    }
});
export default i18n;
